<template>
    <div>
        <div id="metrics-intro" class="carousel slide" data-bs-touch="false">
            <img class="metric-intro-logo" src="../assets/img/logo.png" alt="metrics logo" style="width:90px;height:30px">
            <div class="custom-indicators">
                <button v-if="!carousel.last" type="button" class="btn btn-primary rounded shadowed text-center">
                    <ion-icon @click.prevent="carousel.instance.to((carousel.current+1)%5);" id="intro-next" name="chevron-forward-outline"></ion-icon>
                </button>
                <a href="/register" target="_parent" class="btn btn-primary rounded shadowed text-center" v-else>
                    Continue
                </a>
            </div>

            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div :style="{backgroundImage: 'url('+introImg1+')', height: window_height+'px'}" class="img-container"></div>
                    <div class="carousel-caption d-md-block">
                        <h2>Advanced Hardware Security</h2>
                        <p class="mt-4">
                            EarnRelai offers free hardware wallet shipped to you with advanced security for your
                            cryptocurrency.
<!--                            Simple, secure, industry-standard and elite wallet to keep your assets safe, based on over 10 million users globally.-->
                        </p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div :style="{backgroundImage: 'url('+introImg2+')', height: window_height+'px'}" class="img-container"></div>
                    <div class="carousel-caption d-md-block">
                        <h2>Accept Crypto</h2>
                        <p class="mt-4">
                            Accept any crypto payment from anyone anywhere in the world
                            and get settled in less than 24 hours
                        </p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div :style="{backgroundImage: 'url('+introImg5+')', height: window_height+'px'}" class="img-container"></div>
                    <div class="carousel-caption d-md-block">
                        <h2>Low Transaction Fees</h2>
                        <p class="mt-4">
                            For as low as less than 1.5%, sending any crypto asset takes less than seconds to complete
                        </p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div :style="{backgroundImage: 'url('+introImg4+')', height: window_height+'px'}" class="img-container"></div>
                    <div class="carousel-caption d-md-block">
                        <h2>24/7 Human Support</h2>
                        <p class="mt-4">
                            We won't leave you out on your own. We provide an online help desk and 24/7 customer support to get your questions answered, fast.
                            Our support is always ready to help you. Get quick and efficient response via live chat or email.
                        </p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div :style="{backgroundImage: 'url('+introImg3+')', height: window_height+'px'}" class="img-container"></div>
                    <div class="carousel-caption d-md-block">
                        <img src="../assets/img/logo-icon.png" alt="metrics icon" style="width:120px;height:120px">
                        <h2 class="mt-4">
                            Deposit, Send, Exchange all with EarnRelai
                        </h2>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
// @ is an alias to /src
import introImg1 from '../assets/img/intro/hw_token.jpeg';
import introImg2 from '../assets/img/intro/intro-2.png';
import introImg3 from '../assets/img/intro/intro-3.jpg';
import introImg4 from '../assets/img/intro/intro-4.jpg';
import introImg5 from '../assets/img/intro/intro-5.png';

export default {
    name: 'Intro',
    data(){
        return {
            carousel: {
                current: 0,
                last: false,
                instance: null
            },
            introImg1,
            introImg2,
            introImg3,
            introImg4,
            introImg5,
            window_height: 0
        }
    },
    components: {

    },
    methods: {

    },
    mounted(){
        this.$globalFunc.ScrollAction.disableScroll();
        setTimeout(()=>{
            $('#loader').fadeOut()
        }, 1000);
        let myCarousel = document.querySelector('#metrics-intro');
        this.carousel.instance = new bootstrap.Carousel(myCarousel, {
            interval: false,
            touch: false
        });
        this.window_height = window.innerHeight;
        $('#metrics-intro').swiperight(()=> {
            // if(this.carousel.current > 0){
                this.carousel.instance.to((this.carousel.current-1)%5);
            // }
        });
        $('#metrics-intro').swipeleft(()=> {
            if(!this.carousel.last){
                this.carousel.instance.to((this.carousel.current+1)%5);
            }
        });
        myCarousel.addEventListener('slide.bs.carousel',  (e)=> {
            this.carousel.last = e.to === 4;
            this.carousel.current = e.to;
        })


        if(!this.$store.state.first_timer){
            // FIXME: comment or remove underneath after testing template
            // this.$router.push({name: 'MetricsWallet'})
            // skip this intro page and decide
            // if go to login page or app page
        }
    },
}
</script>

<style scoped>
    .custom-indicators{
        position: fixed;
        display: flex;
        justify-content: center;
        bottom: 60px;
        z-index: 2;
        width: 100%;
    }
    .carousel-caption{
        position: absolute;
        top: 0;
    }
    @media screen and (min-width: 320px){
        .carousel-caption{
            padding-top: 35%
        }
    }
    @media screen and (min-width: 1024px){
        .carousel-caption{
            padding-top: 20%
        }
    }
    .carousel-caption h2,
    .carousel-caption p{
        color: #ffffff;
        text-shadow: 1px 1px 1px #000000;
    }
    #metrics-intro{
        overflow: hidden;
    }
    .carousel-item{
        position: relative;
    }
    .carousel-item:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.7);
    }
    .metric-intro-logo{
        position: fixed;
        top: 10px;
        left: 10px;
        width: 200px;
        z-index: 2
    }
    .img-container{
        background: no-repeat center center;
        background-size: cover;
    }
    @media screen and (min-width: 320px){
        .carousel-item img.h-100-f{
            height: 100%!important;
        }
    }
    @media screen and (min-width: 768px){
        .carousel-item img.h-100-f{
            height: 100%!important;
        }
    }



</style>
